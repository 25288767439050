import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';
import Button from 'shared/Button';
import CarrierForms from 'shared/CarrierForms';
import Field from 'shared/Field';
import Input from 'shared/Input';

import { FontSizes, FontWeights } from 'constants/Clementine';

import FormsEngineApi from 'utils/FormsEngineApi';
import FormUtils from 'utils/Form';

class EmailFormsModal extends React.Component {
  static propTypes = {
    formData: PropTypes.object,
    forms: PropTypes.array,
    onClose: PropTypes.func,
    selectedForms: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      current_view: 'review',
      selected_forms: null,
      forms_download_request: null,
      errors: [],

      to: '',
      cc: '',
      bcc: '',
      from: '',
      subject: 'Your Requested Documents',
      message: 'Attached are the documents you requested.'
    };
  }

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _handleFormClick = selected_forms => {
    this.setState({
      selected_forms
    });
  };

  _toggleView = current_view => {
    this.setState({ current_view });
  };

  _generatePdf = () => {
    const { to, cc, bcc, from, subject, message, selected_forms } = this.state;
    const errors = [];

    if (!FormUtils._validateEmail(to)) {
      errors.push('to');
    }

    if (cc && !FormUtils._validateEmail(cc)) {
      errors.push('cc');
    }

    if (bcc && !FormUtils._validateEmail(bcc)) {
      errors.push('bcc');
    }

    if (!FormUtils._validateEmail(from)) {
      errors.push('from');
    }

    if (!subject.length) {
      errors.push('subject');
    }

    if (!message.length) {
      errors.push('message');
    }

    if (errors.length) {
      this.setState({
        errors
      });
    } else {
      this.setState({
        loading: true
      });

      FormsEngineApi.generatePdf(
        Object.assign({}, this.props.formData, {
          form_guids: selected_forms || this.props.selectedForms,
          pdf_action: 'email',
          to,
          cc,
          bcc,
          from,
          subject,
          message
        })
      ).then(forms_download_request => {
        this.setState({
          current_view: 'success',
          forms_download_request,
          loading: false,
          errors: []
        });
      });
    }
  };

  _openPdf = () => {
    window.open(this.state.forms_download_request.pdf_url);
  };

  render() {
    const { forms, onClose, selectedForms } = this.props;
    const { current_view, selected_forms, loading, errors, to, cc, bcc, from, subject, message } = this.state;
    const styles = this.styles();
    const filtered_forms = forms.filter(f => selectedForms.includes(f.guid));
    const titles = {
      review: 'Email: Review Forms',
      info: 'Email: Recipient Information',
      success: ''
    };
    const buttons = {
      review: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onClose,
          style: { margin: '0 10px 0 auto' },
          children: 'Cancel'
        },
        {
          onClick: this._toggleView.bind(null, 'info'),
          children: 'Next'
        }
      ],
      info: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: this._toggleView.bind(null, 'review'),
          style: { margin: '0 10px 0 auto' },
          children: 'Back'
        },
        {
          onClick: this._generatePdf.bind(null, 'info'),
          children: 'Send Email'
        }
      ]
    };

    return (
      <Modal buttons={buttons[current_view]} loading={loading} maxWidth={800} onClose={onClose} title={titles[current_view]}>
        {current_view === 'review' ? (
          <div style={styles.content}>
            <div style={styles.review_wrapper}>
              <CarrierForms forms={filtered_forms} layout='list' onFormClick={this._handleFormClick} selectedForms={selected_forms || selectedForms} />
            </div>
          </div>
        ) : null}

        {current_view === 'info' ? (
          <div style={styles.content}>
            <Field label='From'>
              <Input invalid={errors.includes('from')} onChange={this._handleInputChange.bind(null, 'from')} required={true} value={from} />
            </Field>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Field label='To' style={{ width: '32%' }}>
                <Input invalid={errors.includes('to')} onChange={this._handleInputChange.bind(null, 'to')} required={true} value={to} />
              </Field>
              <Field label='CC' style={{ width: '32%' }}>
                <Input invalid={errors.includes('cc')} onChange={this._handleInputChange.bind(null, 'cc')} value={cc} />
              </Field>
              <Field label='BCC' style={{ width: '32%' }}>
                <Input invalid={errors.includes('bcc')} onChange={this._handleInputChange.bind(null, 'bcc')} value={bcc} />
              </Field>
            </div>
            <Field label='Subject'>
              <Input invalid={errors.includes('subject')} onChange={this._handleInputChange.bind(null, 'subject')} required={true} value={subject} />
            </Field>
            <Field label='Message'>
              <Input invalid={errors.includes('message')} onChange={this._handleInputChange.bind(null, 'message')} required={true} style={{ height: 195 }} type='textarea' value={message} />
            </Field>
          </div>
        ) : null}

        {current_view === 'success' ? (
          <div style={styles.content}>
            <div style={styles.success_wrapper}>
              <div style={styles.success_title}>Success!</div>
              <div style={styles.success_description}>
                The selected forms have been combined into a single PDF and emailed to these emails:
                <div style={{ margin: '20px 0' }}>
                  <div style={{ fontWeight: FontWeights.MEDIUM }}>{to}</div>
                  {cc.length ? <div style={{ fontWeight: FontWeights.MEDIUM }}>{cc}</div> : null}
                  {bcc.length ? <div style={{ fontWeight: FontWeights.MEDIUM }}>{bcc}</div> : null}
                </div>
                Click the button below to view the PDF and download it for your own records.
              </div>
              <Button inline={true} onClick={this._openPdf}>
                View Combined PDF
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        width: 800
      },
      review_wrapper: {
        borderTop: '1px solid #e5e5e5',
        borderLeft: '1px solid #e5e5e5',
        borderRadius: 4,
        overflow: 'hidden',
        marginBottom: -1
      },
      success_wrapper: {
        textAlign: 'center',
        width: '60%',
        margin: '0 auto'
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40,
        lineHeight: '1.5em'
      }
    };
  };
}

export default EmailFormsModal;
