import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import Button from 'shared/Button';

import { Colors, FontFamilies, FontWeights, FontSizes } from 'constants/Clementine';

class CriteriaPrompt extends React.Component {
  static propTypes = {
    formData: PropTypes.object,
    onGetFormsClick: PropTypes.func,
    onResetCriteriaClick: PropTypes.func,
    ranFormData: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      has_new_criteria: false
    };
  }

  static getDerivedStateFromProps(props) {
    const errors = _validateCriteria(props);
    const has_new_criteria = _checkForNewCriteria(props);

    return {
      errors,
      has_new_criteria
    };
  }

  render() {
    const styles = this.styles();
    const { errors, has_new_criteria } = this.state;
    const { ranFormData, onGetFormsClick, onResetCriteriaClick } = this.props;

    return has_new_criteria || !ranFormData ? (
      <div style={styles.container}>
        <div style={styles.content}>
          <div style={styles.title}>{has_new_criteria ? 'Forms Criteria Changed' : 'Select Forms Criteria'}</div>

          <div style={styles.criteria_list}>
            {[
              { id: 'carrier_id', label: 'Carrier' },
              { id: 'product_group_id', label: 'Product Line' },
              { id: 'state', label: 'State' }
            ].map(criteria => {
              const valid = !errors.includes(criteria.id);

              return (
                <div key={criteria.id} style={styles.criteria}>
                  <i className='mdi mdi-checkbox-marked-outline' style={Object.assign({}, styles.checkbox, valid && styles.checkbox_valid)} />
                  {criteria.label}
                </div>
              );
            })}
          </div>

          <div style={styles.actions}>
            <Button disabled={errors.length > 0} inline={true} onClick={onGetFormsClick}>
              View Forms
            </Button>
            {has_new_criteria ? (
              <div onClick={onResetCriteriaClick} style={styles.link}>
                Back to Current Forms
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }

  styles = () => {
    return {
      container: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 2,
        background: 'rgba(255,255,255,0.95)',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      content: {
        width: 300,
        textAlign: 'center',
        position: 'sticky',
        top: 200,
        left: '50%'
      },
      title: {
        fontSize: FontSizes.XLARGE,
        fontFamily: FontFamilies.ACCENT,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 15
      },
      criteria_list: {
        marginBottom: 25,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      criteria: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.REGULAR,
        lineHeight: '36px',
        display: 'flex',
        alignItems: 'center',
        width: 142,
        textAlign: 'left'
      },
      checkbox: {
        color: '#e5e5e5',
        fontSize: 20,
        marginRight: 8
      },
      checkbox_valid: {
        color: Colors.GREEN.hex
      },
      link: {
        color: this.context.color,
        fontWeight: FontWeights.MEDIUM,
        fontSize: FontSizes.MEDIUM,
        textAlign: 'center',
        marginTop: 15,
        cursor: 'pointer'
      }
    };
  };
}

const _checkForNewCriteria = ({ formData, ranFormData }) => {
  let criteria_changes = 0;

  if (ranFormData) {
    Object.keys(ranFormData).forEach(key => {
      const old_value = ranFormData[key];
      const new_value = formData[key];

      if (Array.isArray(old_value)) {
        const same_values = old_value.filter(c => new_value.includes(c));

        criteria_changes += same_values.length === new_value.length && same_values.length === old_value.length ? 0 : 1;
      } else {
        criteria_changes += old_value === new_value ? 0 : 1;
      }
    });
  }

  return criteria_changes > 0;
};

const _validateCriteria = ({ formData }) => {
  const errors = [];

  if (!formData.carrier_id) {
    errors.push('carrier_id');
  }

  if (!formData.product_group_id) {
    errors.push('product_group_id');
  }

  if (!formData.state) {
    errors.push('state');
  }

  return errors;
};

CriteriaPrompt.contextType = ThemeContext;

export default CriteriaPrompt;
