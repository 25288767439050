import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import Card from 'shared/Card';
import Checklist from 'shared/Checklist';
import Input from 'shared/Input';

import { FontWeights, FontSizes } from 'constants/Clementine';

class ChecklistCard extends React.Component {
  static propTypes = {
    _additional: PropTypes.node,
    _additionalSelected: PropTypes.number,
    header: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.node.isRequired,
        label: PropTypes.node
      })
    ).isRequired,
    id: PropTypes.string,
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    showSelectAll: PropTypes.bool,
    visibleCount: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      show_all: false,
      quick_search: ''
    };
  }

  _selectAll = () => {};

  _toggleShowAll = () => {
    this.setState({
      show_all: !this.state.show_all
    });
  };

  render() {
    const { show_all, quick_search } = this.state;
    const { header, id, options, selected, onChange, onSelectAll, showSelectAll, _additional, _additionalSelected, style, visibleCount = 3 } = this.props;
    const styles = this.styles();
    const footer = (
      <div onClick={this._toggleShowAll} style={styles.footer}>
        {quick_search.length ? 'Showing Results' : <React.Fragment>{show_all ? 'Show Less' : 'Show More'}</React.Fragment>}
        {!quick_search.length ? <i className={show_all ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} style={styles.footer_icon} /> : null}
      </div>
    );

    let visible_options = options.slice(0, visibleCount);

    if (quick_search.length) {
      visible_options = options
        .filter(o => {
          const label = (o.label || o.value).toLowerCase();
          const search_string = quick_search.toLowerCase();

          return label.includes(search_string);
        })
        .slice(0, 3);
    } else if (show_all) {
      visible_options = options;
    }

    return (
      <Card id={id} footer={options.length > visibleCount ? footer : null} header={header} style={style}>
        {selected.length || _additionalSelected ? <div style={styles.selected}>{selected.length + (_additionalSelected || 0)} Selected</div> : null}
        {options.length > 10 ? (
          <div style={styles.actions}>
            <Input
              onChange={e => {
                this.setState({
                  quick_search: e.target.value
                });
              }}
              placeholder='Quick search...'
              value={this.state.quick_search}
            />
            {showSelectAll ? <i className='mdi mdi-checkbox-marked-outline' onClick={onSelectAll} style={styles.select_all} /> : null}
          </div>
        ) : null}
        <Checklist onChange={onChange} options={visible_options} selected={selected} style={{ margin: '-10px 0px' }} />
        {_additional}
      </Card>
    );
  }

  styles = () => {
    const { color } = this.context;

    return {
      selected: {
        background: color,
        color: '#fff',
        padding: '8px 12px',
        fontSize: FontSizes.SMALL,
        fontWeight: FontWeights.MEDIUM,
        margin: '-20px -20px 20px'
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 25
      },
      select_all: {
        color,
        cursor: 'pointer',
        fontSize: 20,
        marginLeft: 10
      },
      footer: {
        padding: '12px 20px',
        color,
        cursor: 'pointer',
        fontSize: 12,
        fontWeight: FontWeights.MEDIUM,
        borderTop: '1px solid #e5e5e5',
        backgroundColor: '#f9f9f9',
        position: 'relative',
        borderRadius: '0 0 4px 4px',
        position: 'relative'
      },
      footer_icon: {
        fontSize: 22,
        position: 'absolute',
        right: 19,
        top: 8
      }
    };
  };
}

ChecklistCard.contextType = ThemeContext;

export default ChecklistCard;
