import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeContext } from 'shared/ThemeContext';

import Card from 'shared/Card';
import ChecklistCard from 'shared/ChecklistCard';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Legal from 'shared/Legal';
import Loader from 'shared/Loader';
import Select from 'shared/Select';
import ZeroState from 'shared/ZeroState';

import CriteriaPrompt from 'forms_engine/CriteriaPrompt';
import FormResults from 'forms_engine/FormResults';

import { Colors, States } from 'constants/Clementine';

import FormsEngineApi from 'utils/FormsEngineApi';
import Notification from '../components/Notifications/Toasts';

class FormsEngine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view_mode: 'grid',

      loading_errors: [],
      loading_engine: true,
      loading_carriers: true,
      loading_form_tags: true,
      loading_forms: false,

      forms_engine: {},
      color: Colors.BLUE.hex,
      carriers: null,
      product_groups: null,
      form_tags: [],
      forms: [],

      form_data: {
        search: '',
        carrier_id: '',
        product_group_id: '',
        state: '',
        form_tag_ids: []
      },
      ran_form_data: null
    };
  }

  componentDidMount() {
    FormsEngineApi.loadFormsEngine().then(response => {
      if (!response) {
        this.setState({
          loading_engine: false,
          loading_carriers: false,
          loading_errors: [{ title: 'An unknown error occurred.' }]
        });
      } else if (response.errors) {
        this.setState({
          loading_engine: false,
          loading_carriers: false,
          loading_errors: response.errors
        });
      } else {
        this.setState({
          loading_engine: false,
          forms_engine: response,
          color: response.theme_options.color || Colors.BLUE.hex
        });

        FormsEngineApi.loadCarriers(response.carrier_ids).then(carriers => {
          this.setState({
            loading_carriers: false,
            carriers
          });
        });

        FormsEngineApi.loadFormTags().then(form_tags => {
          this.setState({
            loading_form_tags: false,
            form_tags
          });
        });
      }
    });
  }

  _handleCarrierChange = e => {
    this.setState({
      form_data: Object.assign({}, this.state.form_data, {
        carrier_id: e.target.value,
        product_group_id: '',
        state: ''
      }),
      product_groups: null
    });

    FormsEngineApi.loadProductGroups(e.target.value).then(product_groups => {
      this.setState({
        product_groups: product_groups.filter(pg => pg.has_forms)
      });
    });
  };

  _handleProductGroupChange = e => {
    this.setState({
      form_data: Object.assign({}, this.state.form_data, {
        product_group_id: e.target.value
      })
    });
  };

  _handleStateChange = e => {
    this.setState({
      form_data: Object.assign({}, this.state.form_data, {
        state: e.target.value
      })
    });
  };

  _handleSearchChange = e => {
    this.setState({
      form_data: Object.assign({}, this.state.form_data, {
        search: e.target.value
      })
    });
  };

  _handleFormTagsChange = form_tag_ids => {
    this.setState({
      form_data: Object.assign({}, this.state.form_data, {
        form_tag_ids
      })
    });
  };

  _resetCriteria = () => {
    this.setState({
      form_data: this.state.ran_form_data
    });
  };

  _getForms = () => {
    const { form_data } = this.state;

    this.setState({
      loading_forms: true
    });

    FormsEngineApi.getForms(form_data).then(forms => {
      this.setState({
        loading_forms: false,
        forms,
        ran_form_data: form_data
      });
    });
  };

  render() {
    const { carriers, product_groups, form_tags, forms, form_data, ran_form_data, loading_errors, loading_forms, color } = this.state;
    const styles = this.styles();

    if (!loading_errors.length) {
      return (
        <ThemeContext.Provider value={{ color }}>
          <Notification color={color} message='Welcome to Forms Engine' />
          <div style={styles.container}>
            <div style={styles.config}>
              <Card>
                <Input onChange={this._handleSearchChange} placeholder='Search by name or number...' value={form_data.search} />
              </Card>
              <Card header={{ label: 'Product' }}>
                {carriers ? (
                  <React.Fragment>
                    <Field label='Carrier' style={{ marginBottom: product_groups ? 20 : 0 }}>
                      <Select onChange={this._handleCarrierChange} options={carriers.map(c => ({ label: c.name, value: c.id }))} placeholder='-- Select A Carrier --' value={form_data.carrier_id} />
                    </Field>

                    {product_groups ? (
                      <React.Fragment>
                        <Field label='Product Line'>
                          <Select
                            onChange={this._handleProductGroupChange}
                            options={product_groups.map(pg => ({ value: pg.id, label: pg.group_name }))}
                            placeholder='-- Select A Product Line --'
                            value={form_data.product_group_id}
                          />
                        </Field>

                        <Field label='State' style={{ marginBottom: 0 }}>
                          <Select onChange={this._handleStateChange} options={Object.values(States)} placeholder='-- Select A State --' value={form_data.state} />
                        </Field>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </Card>

              <ChecklistCard
                header={{ label: 'Categories' }}
                onChange={this._handleFormTagsChange}
                options={form_tags.map(ft => ({ value: ft.id, label: ft.name }))}
                selected={form_data.form_tag_ids}
                style={{ marginBottom: 0 }}
                visibleCount={10}
              />

              <Legal />
            </div>
            <div style={styles.results}>
              {loading_forms ? (
                <div style={styles.loader}>
                  <Loader />
                </div>
              ) : null}

              <CriteriaPrompt formData={form_data} onGetFormsClick={this._getForms} onResetCriteriaClick={this._resetCriteria} ranFormData={ran_form_data} />

              <FormResults formData={ran_form_data} forms={forms} formTags={form_tags} />
            </div>
          </div>
        </ThemeContext.Provider>
      );
    } else {
      return (
        <ZeroState
          icon='mdi-alert-circle-outline'
          message={
            <div>
              {loading_errors[0].title} Please contact IXN Support at{' '}
              <a href='mailto:support@ixntech.com' style={{ color }}>
                support@ixntech.com
              </a>
              .
            </div>
          }
        />
      );
    }
  }

  styles = () => {
    return {
      container: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      config: {
        width: 300,
        marginRight: 20,
        flexShrink: 0
      },
      results: {
        flex: 1,
        position: 'relative',
        minWidth: 0
      },
      loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.95)',
        zIndex: 3
      }
    };
  };
}

ReactDOM.render(<FormsEngine />, document.getElementById('ixn-forms-engine'));
