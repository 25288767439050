import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';
import Button from 'shared/Button';
import CarrierForms from 'shared/CarrierForms';

import { FontSizes, FontWeights } from 'constants/Clementine';

import FormsEngineApi from 'utils/FormsEngineApi';

class DownloadFormsModal extends React.Component {
  static propTypes = {
    formData: PropTypes.object,
    forms: PropTypes.array,
    onClose: PropTypes.func,
    selectedForms: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      current_view: 'review',
      selected_forms: null,
      forms_download_request: null
    };
  }

  _handleFormClick = selected_forms => {
    this.setState({
      selected_forms
    });
  };

  _generatePdf = () => {
    this.setState({
      loading: true
    });

    FormsEngineApi.generatePdf(
      Object.assign({}, this.props.formData, {
        form_guids: this.state.selected_forms || this.props.selectedForms,
        pdf_action: 'download'
      })
    ).then(forms_download_request => {
      this.setState({
        current_view: 'success',
        forms_download_request,
        loading: false
      });

      window.open(forms_download_request.pdf_url, '_blank');
    });
  };

  _openPdf = () => {
    window.open(this.state.forms_download_request.pdf_url, '_blank');
  };

  render() {
    const { forms, onClose, selectedForms } = this.props;
    const { current_view, selected_forms, loading } = this.state;
    const styles = this.styles();
    const filtered_forms = forms.filter(f => selectedForms.includes(f.guid));
    const titles = {
      review: 'Download: Review Forms',
      success: ''
    };
    const buttons = {
      review: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onClose,
          style: { margin: '0 10px 0 auto' },
          children: 'Cancel'
        },
        {
          onClick: this._generatePdf,
          children: 'Download'
        }
      ]
    };

    return (
      <Modal buttons={buttons[current_view]} loading={loading} maxWidth={800} onClose={onClose} title={titles[current_view]}>
        {current_view === 'review' ? (
          <div style={styles.content}>
            <div style={styles.review_wrapper}>
              <CarrierForms forms={filtered_forms} layout='list' onFormClick={this._handleFormClick} selectedForms={selected_forms || selectedForms} />
            </div>
          </div>
        ) : null}

        {current_view === 'success' ? (
          <div style={styles.content}>
            <div style={styles.success_wrapper}>
              <div style={styles.success_title}>Success!</div>
              <div style={styles.success_description}>Your forms have been combined into a single PDF and opened in a new window. If a new window didn't open, click the button below.</div>
              <Button inline={true} onClick={this._openPdf}>
                View Combined PDF
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 20,
        width: 800
      },
      review_wrapper: {
        borderTop: '1px solid #e5e5e5',
        borderLeft: '1px solid #e5e5e5',
        borderRadius: 4,
        overflow: 'hidden',
        marginBottom: -1
      },
      success_wrapper: {
        textAlign: 'center',
        width: '60%',
        margin: '0 auto'
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40
      }
    };
  };
}

export default DownloadFormsModal;
