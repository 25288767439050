import Axios from 'axios';

import AppConfig from 'config/App';

const token = window.IXN_FORMS_ENGINE_CONFIG.id;
const request = Axios.create({
  baseURL: `${AppConfig.API_BASE_URL}`,
  headers: {
    Accept: 'application/json'
  }
});

const FormsEngineApi = {
  loadFormsEngine() {
    request.defaults.headers['FORM-ENGINE-TOKEN'] = token;

    return request
      .get(`/v1/form_engine/${token}`)
      .then(response => response.data)
      .catch(response => response.data);
  },

  loadCarriers(carrier_ids) {
    const params = {
      id: carrier_ids,
      forms: true
    };

    return request.get('/v1/carriers', { params }).then(response => response.data);
  },

  loadProductGroups(carrier_id) {
    return request.get(`/v1/product_groups?carrier_id=${carrier_id}`).then(response => {
      return response.data;
    });
  },

  loadFormTags() {
    return request.get('/v1/form_tags').then(response => {
      return response.data;
    });
  },

  getForms(params) {
    return request.get('/v1/forms', { params }).then(response => {
      return response.data;
    });
  },

  generatePdf(params) {
    return request.post('/v1/forms', params).then(response => {
      return response.data;
    });
  }
};

export default FormsEngineApi;
