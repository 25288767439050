import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import { FontSizes, FontWeights } from 'constants/Clementine';

const Card = ({ children, id = 'card', header, footer, style }) => {
  const context = React.useContext(ThemeContext);
  const styles = {
    component: Object.assign(
      {},
      {
        border: '1px solid #e5e5e5',
        borderRadius: 4,
        background: '#fff',
        marginBottom: 20
      },
      style
    ),
    header: {
      padding: 12,
      fontSize: FontSizes.REGULAR,
      fontWeight: FontWeights.MEDIUM,
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderBottom: '1px solid #e5e5e5'
    },
    header_action: {
      color: context.color,
      cursor: 'pointer'
    },
    body: {
      padding: 20
    }
  };

  return (
    <div id={id} style={styles.component}>
      {header ? (
        <div style={styles.header}>
          <div style={styles.title}>{header.label}</div>
          {header ? (
            <div onClick={header.action_onClick} style={styles.header_action}>
              {header.action_label}
            </div>
          ) : null}
        </div>
      ) : null}
      {children ? <div style={styles.body}>{children}</div> : null}
      {footer}
    </div>
  );
};

Card.contextType = ThemeContext;

Card.propTypes = {
  footer: PropTypes.node,
  header: PropTypes.shape({
    label: PropTypes.node,
    action_onClick: PropTypes.func,
    action_label: PropTypes.node
  })
};

export default Card;
