import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

import { ThemeContext } from 'shared/ThemeContext';

import Button from 'shared/Button';
import CarrierForms from 'shared/CarrierForms';
import Select from 'shared/Select';

import DownloadFormsModal from 'forms_engine/DownloadFormsModal';
import EmailFormsModal from 'forms_engine/EmailFormsModal';
import TextFormsModal from 'forms_engine/TextFormsModal';

import { Colors, FontWeights } from 'constants/Clementine';

class FormResults extends React.Component {
  static propTypes = {
    formData: PropTypes.object,
    forms: PropTypes.array,
    formTags: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      layout: 'grid',
      sort_by: 'form_name,asc',

      selected_forms: [],

      show_download_modal: false,
      show_email_modal: false,
      show_text_modal: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const form_guids = props.forms.map(f => f.guid);

    return Object.assign({}, state, {
      selected_forms: state.selected_forms.filter(guid => form_guids.includes(guid))
    });
  }

  _toggleLayout = () => {
    this.setState({
      layout: this.state.layout === 'grid' ? 'list' : 'grid'
    });
  };

  _toggleModal = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  _handleSelectAll = () => {
    const { forms } = this.props;
    const { selected_forms } = this.state;

    this.setState({
      selected_forms: forms.length === selected_forms.length ? [] : forms.map(f => f.guid)
    });
  };

  _handleSelectChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _handleFormClick = selected_forms => {
    this.setState({
      selected_forms
    });
  };

  render() {
    const styles = this.styles();
    const { color } = this.context;
    const { formData, forms, formTags } = this.props;
    const { selected_forms, layout, sort_by, show_download_modal, show_email_modal, show_text_modal } = this.state;
    const sort_by_parts = sort_by.split(',');
    const sorted_forms = _orderBy(forms, [sort_by_parts[0]], [sort_by_parts[1]]);
    const is_grid_layout = layout === 'grid';

    return (
      <div style={styles.container}>
        {show_download_modal ? <DownloadFormsModal formData={formData} forms={forms} onClose={this._toggleModal.bind(null, 'show_download_modal')} selectedForms={selected_forms} /> : null}

        {show_email_modal ? <EmailFormsModal formData={formData} forms={forms} onClose={this._toggleModal.bind(null, 'show_email_modal')} selectedForms={selected_forms} /> : null}

        {show_text_modal ? <TextFormsModal formData={formData} forms={forms} onClose={this._toggleModal.bind(null, 'show_text_modal')} selectedForms={selected_forms} /> : null}

        <div style={styles.header}>
          <Button onClick={this._handleSelectAll}>
            <i className='mdi mdi-checkbox-marked-outline' style={{ fontSize: 18, verticalAlign: 'top', marginLeft: -3 }} />
            {forms.length === selected_forms.length ? ' Unselect' : ' Select'} All {forms.length} Forms
          </Button>

          <div style={{ display: 'flex' }}>
            <Select
              onChange={this._handleSelectChange.bind(null, 'sort_by')}
              options={[
                { value: 'form_name,asc', label: 'Name (A-Z)' },
                { value: 'form_name,desc', label: 'Name (Z-A)' },
                { value: 'form_number,asc', label: 'Number (A-Z)' },
                { value: 'form_number,desc', label: 'Number (Z-A)' }
              ]}
              selected={sort_by}
            />
            <Button
              color={is_grid_layout ? color : '#fff'}
              fontColor={is_grid_layout ? null : color}
              onClick={this._toggleLayout}
              style={{ borderRadius: '4px 0px 0px 4px', marginLeft: 10, padding: '0px 15px' }}
            >
              <i className='mdi mdi-view-grid' style={styles.btn_icon} />
            </Button>
            <Button color={is_grid_layout ? '#fff' : color} fontColor={is_grid_layout ? color : null} onClick={this._toggleLayout} style={{ borderRadius: '0px 4px 4px 0px', padding: '0px 15px' }}>
              <i className='mdi mdi-menu' style={styles.btn_icon} />
            </Button>
          </div>
        </div>

        {selected_forms.length ? (
          <div style={styles.selected_bar}>
            <div>{selected_forms.length} Forms Selected</div>
            <div style={{ display: 'flex' }}>
              <Button icon='download' onClick={this._toggleModal.bind(null, 'show_download_modal')} small={true}>
                Download
              </Button>
              <Button icon='email' onClick={this._toggleModal.bind(null, 'show_email_modal')} small={true} style={{ margin: '0px 4px' }}>
                Email
              </Button>
              <Button icon='forum' onClick={this._toggleModal.bind(null, 'show_text_modal')} small={true}>
                Text
              </Button>
            </div>
          </div>
        ) : null}

        <div style={styles.content}>
          <CarrierForms forms={sorted_forms} formTags={formTags} layout={layout} onFormClick={this._handleFormClick} selectedForms={selected_forms} />
        </div>

        <div style={styles.footer}>
          <div style={styles.total}>{forms.length} Results</div>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      container: {
        background: '#fff',
        borderRadius: 4,
        border: '1px solid #e5e5e5'
      },
      header: {
        background: '#f9f9f9',
        padding: 15,
        borderBottom: '1px solid #e5e5e5',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      content: {
        maxHeight: 700,
        overflow: 'auto'
      },
      selected_bar: {
        background: Colors.GRAY.hex,
        color: '#fff',
        padding: '10px 15px 10px 18px',
        fontWeight: FontWeights.MEDIUM,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      btn_icon: {
        fontSize: 18,
        verticalAlign: 'top'
      },
      footer: {
        background: '#f9f9f9',
        padding: 15,
        borderRadius: '0px 0px 4px 4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #e5e5e5'
      },
      total: {
        fontWeight: FontWeights.MEDIUM
      }
    };
  };
}

FormResults.contextType = ThemeContext;

export default FormResults;
